<template>
  <div class="content">
    <div class="row" style="background: #2f2f2f ;">
      <section class="text-center col-lg-8 col-sm-12">


        <div
          class="header text-center"
          style="margin-bottom: 35px; width: 100%; background-color: #0e0e0e"
        >

          <div
            class="centered"
            style="width: 100%; "
            :style="{
              background: `linear-gradient(${primaryColor}, black)`,
            }">
          <HeadImage  align="text-center" :image="bgPalm" title="DJ Schedule" caption="Tulum, Mexico" :color="primaryColor"></HeadImage>
          <div class="text-center" style="margin: 20px;  padding: 20px;margin-top:-60px;">


            <hr style="height: 1px;background-color: #262424">

            <p style="background-color: #262424 !important;padding: 20px">Tulum of the biggest
              clusters of Djs  and Artists in all of the continent. Few places truly square up to Tulum in terms of its exclusivity and range of prolific high-profile Djs and Producers.
</p>
          </div>
            <div class="card" style="padding: 20px">

              <div class="text-center">
                <h3 class="section-header">Hot This Week</h3>
              </div>
              <router-link :to="{path:this.highlight.metadata.djschedule.metadata.link }"
              class="card-animation-on-hover-no-bg"
              >
                <ResponsiveImage align="text-center" :image="this.highlight.metadata.main_image.imgix_url" title="DJ Schedule" caption="Tulum, Mexico" :color="primaryColor"></ResponsiveImage>
              </router-link>

              <MiniArtistCard
                style="padding: 20px"
                with-venue="true"
                :event="{title:  this.highlight.metadata.djschedule.title,
                metadata: {main_image: '',
                link: this.highlight.metadata.djschedule.metadata.link,
                                date: this.highlight.metadata.djschedule.metadata.date,
                start_time: this.highlight.metadata.djschedule.metadata.start_time,
                }}"
              ></MiniArtistCard>
            </div>
          </div>
        </div>

          <h4 class="section-header">Full Schedule</h4>

        <br/>
        <div
          class="row row-no-gutters"
          :style="{
              background: `linear-gradient(${secondaryColor}, black)`,
            }">
          <h5 class="section-header text-left floating"></h5>

          <div
            class="col-12 text-center"
            style="margin-top: 10px"
            :key="date"
            v-for="(period, date) in periodDates"
          >
            <div>
              <p
                style="
                background-color: #2eafb4;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 0;
              "
              >
                {{ getDay3(period[0].metadata.date + 'T' + '00:00') }}
              </p>
              <p
                style="
                color: silver !important;
                font-size: 1.4rem;
                background-color: black;
                font-weight: bold;
              "
              >
                {{ getDay2(period[0].metadata.date + 'T' + '00:00') }}
              </p>
            </div>

            <div v-for="(event) in period" :key="event.slug">

              <MiniArtistCard
                with-venue="true"
                :event="event"
                :key="event.slug"
              ></MiniArtistCard>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <small>Total: {{ djSchedule.length }}</small>
        <br/>
        <br/>
        <br/>
        <h4 class="section-header">
          <i class="tim-icons icon-chart-bar-32"
             :style="{color: this.primaryColor, border: `2px solid ${this.secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
          Production Brands
        </h4>
          <BrandCollection></BrandCollection>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu :links="links" :logo="this.instagramTp" ctaText="INFO" top-section-name="GET IN TOUCH"
                   :primary-color="this.primaryColor">
          <div class="row">
            <div class="col-sm-12 text-center">

              <p>
                <strong>Do you need any recommendation</strong> based on your musical taste?
                <br>
                Drop us a line <br>we'll make you dance.
              </p>
              <hr>
            </div>
          </div>


        </SmartMenu>
        <h4 class="section-header">
          <i class="tim-icons icon-chart-bar-32"
             :style="{color: this.primaryColor, border: `2px solid ${this.secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
    Production Brands
        </h4>
          <BrandCollection></BrandCollection>

      </aside>

    </div>

  </div>
</template>

<script>
import moment from 'moment';
import MiniArtistCard from '@/components/Cards/MiniArtistCard';
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
import BrandCollection from '@/components/Cards/BrandCollection';
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import HeadImage from "@/components/HeadImage/HeadImage";
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";
import Carrousel from "@/components/Carrousel";
import {COSMIC_BASE_URL, getArtistHighlight} from '@/common/request'
export default {
  name: 'DjSchedule',
  data: () => {
    return {
      showPalms: false,
      mainImage: 'https://imgix.cosmicjs.com/96fee0a0-094a-11ee-9fa8-55f9b865bb53-Sasha-Mia-Tulum.jpg',
      primaryColor: '#E3C39F',
      secondaryColor: '#2a737a',
      showCalendarTab: false,
      highlight: {title: 'loading', metadata: {main_image:'',djschedule: {title: 'loading', metadata: {}}, }},
      showEventsTab: true,
      period: 'year',
      collapsed: false,
      periodPartiesDates: [],
      listView: true,
      periodParties: [{title: 'LOADING', metadata: {main_image: {}}}],
      calImage:
        'https://image.lexica.art/full_webp/4e714d4e-c8c4-4522-8755-c785bcb4746c',
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      instagramTp:
        'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      gCalImg:
        'https://imgix.cosmicjs.com/a582d0a0-8483-11ee-9ebe-eb22b0517bf0-image.png',
      birdsImg:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      musicIsHope:
        'https://imgix.cosmicjs.com/32f88b10-c704-11ee-9ce5-59949019255e-music-is-hope.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png',
    };
  },
  metaInfo() {
    return {
      title: `Tulum DJ Schedule: Who's Playing When & Where`,
      meta: [
        {
          name: 'description',
          content: `Don't miss a beat! See the hottest DJs spinning in Tulum this week. Our Tulum DJ schedule shows you who's playing, when, and where.`,
        },
        {
          name: 'keywords',
          content: `Tulum dj schedule, events, calendar, clubs, party agenda, dj agenda`,
        },
        {
          property: 'og:title',
          content: `Don't Miss Out! Upcoming Tulum DJ Events`,
        },
        {
          property: 'og:url',
          content: `https://www.tulum.party/tulum-dj-schedule`,
        },
        {
          property: 'og:description',
          content: `Don't miss a beat! See the hottest DJs spinning in Tulum this week. Our Tulum DJ schedule shows you who's playing, when, and where.`,
        },
        {property: 'og:type', content: 'website'},
      ],
    };
  },
  components: {
    ResponsiveImage,
    HeadImage,
    Carrousel,
    getArtistHighlight,
    BrandCollection,
    RsvpViaDialog,
    SmartMenu,
    MiniArtistCard,
  },
  computed: {
    djSchedule: {
      get() {
        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');

          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...this.$store.state.djSchedule]
          .sort((a, b) => {
            let time_a = a.metadata.start_time || '00:00';
            let time_b = b.metadata.start_time || '00:00';
            return moment.utc(a.metadata.date + ' ' + time_a) >
            moment.utc(b.metadata.date + ' ' + time_b)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    periodDates: {
      get() {
        let _periodDates = {};
        this.djSchedule.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            _periodDates[y.metadata.date].push(y);
          } else {
            _periodDates[y.metadata.date] = [];
            _periodDates[y.metadata.date].push(y);
          }
        });
        return _periodDates;
      },
    },
  },
  mounted: function () {
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    this.$store.dispatch('getDjSchedule').then((x) => {
      // this.getThisPeriod('week', 'This Week', true);
    });
     fetch( encodeURI( `${COSMIC_BASE_URL}&props=title,slug,metadata.main_image,metadata.djschedule&query={"type":"artisthighlight","slug":"highlight" }` ) ).then((response) => {
      response.json().then((r) => {
        this.highlight = r.objects[0];
      });
     });
    this.$store.dispatch('getLinks', {slug: 'tulum-party-mexico-event-calendar'});
    setTimeout(() => {
      this.showPalms = true;
    }, 3000);
  },
  methods: {
    getDay: function getYear(date) {
      return moment(date, true).format('ddd');
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD');
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM');
    },
    googleCalendar() {
      window.ga('send', 'event', 'Event', 'Calendar', 'Google Calendar', 1);
      this.$rollbar.info(`Google Calendar Open`);
    },
  },
};
</script>

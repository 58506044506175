var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"row",staticStyle:{"background":"#2f2f2f"}},[_c('section',{staticClass:"text-center col-lg-8 col-sm-12"},[_c('div',{staticClass:"header text-center",staticStyle:{"margin-bottom":"35px","width":"100%","background-color":"#0e0e0e"}},[_c('div',{staticClass:"centered",staticStyle:{"width":"100%"},style:({
              background: ("linear-gradient(" + _vm.primaryColor + ", black)"),
            })},[_c('HeadImage',{attrs:{"align":"text-center","image":_vm.bgPalm,"title":"DJ Schedule","caption":"Tulum, Mexico","color":_vm.primaryColor}}),_vm._m(0),_c('div',{staticClass:"card",staticStyle:{"padding":"20px"}},[_vm._m(1),_c('router-link',{staticClass:"card-animation-on-hover-no-bg",attrs:{"to":{path:this.highlight.metadata.djschedule.metadata.link }}},[_c('ResponsiveImage',{attrs:{"align":"text-center","image":this.highlight.metadata.main_image.imgix_url,"title":"DJ Schedule","caption":"Tulum, Mexico","color":_vm.primaryColor}})],1),_c('MiniArtistCard',{staticStyle:{"padding":"20px"},attrs:{"with-venue":"true","event":{title:  this.highlight.metadata.djschedule.title,
                metadata: {main_image: '',
                link: this.highlight.metadata.djschedule.metadata.link,
                                date: this.highlight.metadata.djschedule.metadata.date,
                start_time: this.highlight.metadata.djschedule.metadata.start_time,
                }}}})],1)],1)]),_c('h4',{staticClass:"section-header"},[_vm._v("Full Schedule")]),_c('br'),_c('div',{staticClass:"row row-no-gutters",style:({
              background: ("linear-gradient(" + _vm.secondaryColor + ", black)"),
            })},[_c('h5',{staticClass:"section-header text-left floating"}),_vm._l((_vm.periodDates),function(period,date){return _c('div',{key:date,staticClass:"col-12 text-center",staticStyle:{"margin-top":"10px"}},[_c('div',[_c('p',{staticStyle:{"background-color":"#2eafb4","text-transform":"uppercase","font-weight":"bold","margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.getDay3(period[0].metadata.date + 'T' + '00:00'))+" ")]),_c('p',{staticStyle:{"color":"silver !important","font-size":"1.4rem","background-color":"black","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getDay2(period[0].metadata.date + 'T' + '00:00'))+" ")])]),_vm._l((period),function(event){return _c('div',{key:event.slug},[_c('MiniArtistCard',{key:event.slug,attrs:{"with-venue":"true","event":event}})],1)})],2)})],2),_c('br'),_c('br'),_c('small',[_vm._v("Total: "+_vm._s(_vm.djSchedule.length))]),_c('br'),_c('br'),_c('br'),_c('h4',{staticClass:"section-header"},[_c('i',{staticClass:"tim-icons icon-chart-bar-32",style:({color: this.primaryColor, border: ("2px solid " + (this.secondaryColor)), borderRadius: '100px', padding: '5px'})}),_vm._v(" Production Brands ")]),_c('BrandCollection')],1),_c('aside',{staticClass:"card col-4 col-lg-4 d-none d-lg-inline"},[_c('SmartMenu',{attrs:{"links":_vm.links,"logo":this.instagramTp,"ctaText":"INFO","top-section-name":"GET IN TOUCH","primary-color":this.primaryColor}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 text-center"},[_c('p',[_c('strong',[_vm._v("Do you need any recommendation")]),_vm._v(" based on your musical taste? "),_c('br'),_vm._v(" Drop us a line "),_c('br'),_vm._v("we'll make you dance. ")]),_c('hr')])])]),_c('h4',{staticClass:"section-header"},[_c('i',{staticClass:"tim-icons icon-chart-bar-32",style:({color: this.primaryColor, border: ("2px solid " + (this.secondaryColor)), borderRadius: '100px', padding: '5px'})}),_vm._v(" Production Brands ")]),_c('BrandCollection')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center",staticStyle:{"margin":"20px","padding":"20px","margin-top":"-60px"}},[_c('hr',{staticStyle:{"height":"1px","background-color":"#262424"}}),_c('p',{staticStyle:{"background-color":"#262424 !important","padding":"20px"}},[_vm._v("Tulum of the biggest clusters of Djs and Artists in all of the continent. Few places truly square up to Tulum in terms of its exclusivity and range of prolific high-profile Djs and Producers. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"section-header"},[_vm._v("Hot This Week")])])}]

export { render, staticRenderFns }